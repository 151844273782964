<!-- 
  <div class=" bg-no-repeat lg:bg-bottom md:bg-bottom lg:bgimage md:bgimage sm:bgimagesmall  " style="background-image: url('assets/imgs/bghome.png');">

     <img
      class="lg:h-[1400px] md:h-[950px] sm:h-[500px] lg:pt-0 md:pt-0 sm:pt-20 w-full lg:object-cover md:lg:object-cover sm:object-cover lg:object-left-bottom md:object-left-bottom sm:object-center"
      [src]="strapiApi.getImage(homeDetails.background.data.attributes.url)" alt="">
 -->
 <style>
  .cookies-ribbon {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  color: #000000;
  padding: 10px;
  z-index: 9999;
}

.cookies-ribbon p {
  display: inline-block;
  margin: 0;
}

.cookies-ribbon button {
  background-color: rgb(0, 169, 231);
  color: #fff;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
}

.cookies-ribbon a {
  color: rgb(0, 169, 231);
  text-decoration: underline;
}
.cookies-ribbon a:hover {
  color: #1e40af;
}
 </style>
 
<app-header bg="bg-white" class=""></app-header>

<div class=" bg-no-repeat lg:bg-bottom md:bg-bottom lg:bgimage md:bgimage sm:bgimagesmall ">
  
  <div class="absolute inset-0 lg:bottom-0 md:bottom-72" *ngIf="getLang == 'en'">
  
    <div class="slider">
      <div class="slides-container flex" [style.transform]="'translateX(-' + currentIndex * 100 + '%)'">
        <div *ngFor="let slide of slides; let i = index" class="slide">
          <div class="relative">
            
            
          <img [ngClass]="slide.title == 'Drive with Confidence!' ? 'lg:object-contain' : 'lg:object-none'" class=" lg:object-top lg:h-[1500px]  md:pt-20 md:h-[650px] md:object-cover md:object-top sm:object-cover sm:object-right sm:h-[350px] sm:w-full sm:hidden lg:block md:block" [src]="slide.src" [alt]="slide.alt"  style="float: right;">
          <img  class=" lg:hidden md:hidden sm:object-contain sm:h-[450px] sm:w-full pt-40 py-6" [src]="slide.smallImg" [alt]="slide.alt"  style="float: right;">

          <p class="text-base lg:text-left  md:text-left sm:text-center sm:mx-auto  lg:absolute md:absolute lg:top-96 md:top-96 lg:left-[25%] lg:transform lg:-translate-x-1/2 lg:-translate-y-1/2 lg:w-[22%]    md:left-[25%] md:transform md:-translate-x-1/2 md:-translate-y-1/2 md:w-[22%] sm:w-[50%]">
            <span class="lg:text-5xl md:text-5xl sm:text-lg font-medium text-primary-500"> {{slide.title}}</span>
           <br>
          <br>
          
          <span [ngClass]="slide.title == 'EXCITING INSURANCE' ? 'text-white' : ''" class="pt-8 lg:text-lg md:text-base sm:text-sm">{{slide.alt}}</span>
          <br>
          <br>
          <br>
          <a *ngIf="slide.title != 'Drive with Confidence!' && slide.title != 'EXCITING INSURANCE'" href="https://online.solidarity.bh/register" class=" bg-primary-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-block transition duration-300 ease-in-out hover:shadow-md">Sign Up Now</a>
          <a *ngIf="slide.title == 'Drive with Confidence!'" href="/product/motor" class=" bg-primary-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-block transition duration-300 ease-in-out hover:shadow-md">Get a Quote</a>
          <a *ngIf="slide.title == 'EXCITING INSURANCE'" href="https://online.solidarity.bh/contact-form" class=" bg-primary-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-block transition duration-300 ease-in-out hover:shadow-md">Get Offer</a>
          </p>
          <br>
        </div>          
        </div>
      </div>
    </div>

  </div>
  
  <div class="absolute inset-0 lg:bottom-0 md:bottom-72" *ngIf="getLang == 'ar'">
    <div class="slider">
      <div class="slides-container flex" [style.transform]="'translateX(-' + currentIndex * 100 + '%)'">
        <div *ngFor="let slide of slides; let i = index" class="slide">
          <div class="relative">
            
            
          <img [ngClass]="slide.title == 'Drive with Confidence!' ? 'lg:object-contain' : 'lg:object-none'" class=" lg:object-top lg:h-[1500px] md:pt-20 md:h-[650px] md:object-cover md:object-top sm:object-cover sm:object-right sm:h-[350px] sm:w-full sm:hidden lg:block md:block" [src]="slide.src" [alt]="slide.alt"  style="float: right; transform: scaleX(-1);">
          <img  class=" lg:hidden md:hidden sm:object-contain sm:h-[450px] sm:w-full pt-40 py-6" [src]="slide.smallImg" [alt]="slide.alt"  style="float: right;">

          <p class="text-base lg:text-right  md:text-right sm:text-center sm:mx-auto  lg:absolute md:absolute lg:top-96 md:top-96 lg:left-[75%] lg:transform lg:-translate-x-1/2 lg:-translate-y-1/2 lg:w-[22%]    md:left-[75%] md:transform md:-translate-x-1/2 md:-translate-y-1/2 md:w-[22%] sm:w-[50%]">
            <span  class="lg:text-5xl md:text-5xl sm:text-lg font-medium text-primary-500 "> {{slide.titleAr}}</span>
           <br>
          <br>
          
          <span [ngClass]="slide.title == 'EXCITING INSURANCE' ? 'text-white' : ''" class="pt-8 lg:text-lg md:text-base sm:text-sm">{{slide.altAr}}</span>
          <br>
          <br>
          <br>
          <a *ngIf="slide.title != 'Drive with Confidence!'" href="https://online.solidarity.bh/register" class=" bg-primary-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-block transition duration-300 ease-in-out hover:shadow-md">سجل الآن</a>
          <a *ngIf="slide.title == 'Drive with Confidence!'" href="/product/motor" class=" bg-primary-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-block transition duration-300 ease-in-out hover:shadow-md">احصل على تسعيرة</a>
          </p>
          <br>
          
        </div>
   
          
          
        </div>
      </div>
    </div>

  </div>
  <div class="w-full  lg:h-[900px] md:h-[596px] sm:h-[750px] scrollTarget">
    <div class="mx-auto max-w-7xl px-6  items-center lg:pt-[135px] md:pt-[100px] sm:pt-[135px]"
      [ngClass]="getLang == 'en' ? 'flex justify-start' : 'flex justify-end'">
      <div class="flex items-center h-full" id="bottom_stipe_content">
        <div class="md:text-left relative">
        </div>
      </div>
    </div>
  </div>
</div>
<div class="w-full bg-white pb-20 relative lg:pt-40 md:pt-60 sm:pt-12">
  <div class="mx-auto max-w-7xl px-4 justify-center text-center items-center lg:pt-20 md:pt-auto ">
    <div class="grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 gap-x-5 lg:absolute md:absolute inset-x-0 -top-10 lg:-mt-2 lg:px-6 md:px-6 mx-auto lg:max-w-7xl md:max-w-7xl">
      <div *ngFor="let singlebox of boxes" class="border rounded-md border-Light-Gray-3">
        <a href="https://online.solidarity.bh/renewal">
          <app-fatures-box [height]="'h-[300px]'" class="" id="box"
          img="{{strapiApi.getImage(singlebox.icon.data.attributes.url)}}"
          [first_line]="getLang == 'en' ? singlebox.box_title_en : singlebox.box_title_ar"
          [second_line]="getLang == 'en' ? singlebox.box_desc_en : singlebox.box_desc_ar" dir="auto">
        </app-fatures-box>
        <p class="lg:hidden md:hidden sm:block"> <br> </p>
        </a>
      </div>
    </div>


    <div class=" lg:w-2/2 md:w-5/6 sm:w-5/6 mx-auto lg:pt-auto md:pt-auto sm:pt-20">
      <p class="  lg:text-6xl md:text-[40px] sm:text-3xl sm:w-full    font-medium text-Dark-Gray-2 " dir="auto">
        {{getLang == 'en' ? homeDetails.second_title_en : homeDetails.second_title_ar}}
      </p>
      <p class="mx-auto lg:text-lg md:text-base md:block lg:block  sm:hidden  text-Mid-Gray-1 text-center mt-4 w-3/4"
        dir="auto">
        {{getLang == 'en' ? homeDetails.second_desc_en : homeDetails.second_desc_ar}}
      </p>
    </div>

    <div class="w-full grid lg:grid-cols-6 gap-3 mt-10 md:grid-cols-3 lg:px-6 md:px-6 sm:grid-cols-2  " dir="auto">
      <div *ngFor="let product of products">
        <a href="product/{{product.product_identity}}">
          <div
            class=" bg-white group border rounded-md border-Light-Gray-3 shadow-sm text-center place-items-center items-center px-4 py-8 hover:border-primary-600 hover:shadow-lg">
            <div class="max-w-[70px] max-h-[70px] mx-auto ">
              <img viewBox="0 0 70 70" fill="none" class="mx-auto" height="30" width="50"
                [src]="strapiApi.getImage(product.icon.data.attributes.url)" alt="">

            </div>

            <p
              class="mt-3 lg:text-lg md:text-lg sm:text-sm text-Mid-Gray-1 font-normal group-hover:text-primary-600 group-hover:font-bold">
              {{getLang == 'en' ? product.product_name_en : product.product_name_ar}}
            </p>
          </div>
        </a>
      </div>

    </div>
  </div>
  <div class="cookies-ribbon">
    <p>This website uses cookies to ensure you get the best experience. By continuing to browse the site, you are agreeing to our use of cookies and to our <a href="https://www.solidarity.com.bh/privacyandsecurity">privacy policy</a>.</p>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <button id="acceptCookiesBtn" (click)="acceptCookies()" class="ml-4">Accept</button>
  </div>
  <script src="https://cdn.tailwindcss.com/2.2.7/tailwind.min.js"></script>
</div>

<app-channel-guides class="relative"></app-channel-guides>

<app-claim-section class="relative"></app-claim-section>
<app-coverd-section class="relative"></app-coverd-section>
<app-lower-banner class="relative"></app-lower-banner>
<app-footer class="relative"> </app-footer>