<!-- <div class="w-full flex flex-col place-content-end bg-primary-50" dir="auto">
  <div class="lg:hidden md:hidden sm:block object-center w-full mx-auto sm:ml-0">
    <img class="lg:max-h-[480px] md:max-w-md w-full" src="assets/imgs/lower_banner.jpg" />
  </div>
  <div class="container mx-auto flex items-center h-full mt-16 p-0 lg:text-left md: sm:text-center sm:self-center"
    id="bottom_stipe_content">
    <div class="w-2/4 sm:w-full md: pb-10 lg:text-left md:text-left sm:text-center">

      <p class="lg:text-6xl md:text-[40px] sm:text-3xl  font-medium {{getLang == 'en' ? '' : 'text-right'}}">{{getLang == 'en' ? "Home is your safe place." :"منزلك هو مكانك الآمن"}}</p>
      <p class="lg:w-3/4 md:w-60 sm:w-full text-lg text-Mid-Gray-1 text-justify mt-3">
        {{getLang == 'en' ?
        "The great prices, fast claims, and quick renewals are just the beginning. Get the full benefits by signing up with Solidarity." :"الأسعار التنافسية، تقديم المطالبات وتجديد الوثائق في وقت قياسي ليست سوى البداية. احصل على المزايا الكاملة من خلال التسجيل في تطبيق الهاتف الذكي"}}
      </p>
      <a href="https://online.solidarity.bh/">
        <button
          class="button flex lg:w-[168px] md:lg:w-[168px] sm:w-full mt-10 h-10 bg-primary-500 text-Light-Gray-5 py-2 px-4 rounded-md items-center text-sm justify-around"
          dir="auto">
          <p>{{getLang == 'en' ? "Get a Quote" : "تسعيرة التأمين"}}</p>
          <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg"
            [ngClass]="getLang == 'ar' ? 'scale-x-[-1]' : ''">
            <path d="M17 5H1H17Z" fill="white" />
            <path d="M13 1L17 5M17 5L13 9M17 5H1" stroke="white" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </button>
      </a>
    </div> -->
    <!-- <div class="lg:hidden md:block sm:hidden pb-10 w-[2000px] flex flex-col object-right p-11">
      <img class="max-h-[480px] w-full" src="assets/imgs/lower_banner.jpg" />
    </div> -->
    <!-- <div class="relative lg:hidden md:block sm:hidden object-center w-[1400px] mt-80 sm:ml-0">
      <img class="max-h-[900px] w-full absolute bottom-0 right-0" src="assets/imgs/lower_banner.jpg" />
    </div> -->
    <!-- <div class="relative lg:block md:block sm:hidden object-center w-[1400px] mx-4 mt-80 sm:ml-0">
      <img class="max-h-[900px] w-full absolute bottom-0 right-0" src="assets/imgs/lower_banner.jpg" />
    </div>
  </div>

</div> -->

<div class="bg-primary-50" dir="auto">
  <div class="lg:hidden md:hidden sm:block object-center w-full mx-auto sm:ml-0">
    <div></div>
    <img class="max-h-[500px] w-full" src="assets/imgs/lower_banner.jpg" alt="">
  </div>
  <div class="mx-auto max-w-7xl px-4 sm:px-6 container lg:pb-0 md:pt-0 sm:pb-7 pt-14">
    <div class="lg:flex lg:justify-between md:flex md:justify-between">
      <div>
        <p class="lg:text-6xl md:text-[40px] sm:text-[30px] lg:pt-7 md:pt-5 {{getLang == 'en' ? '' : 'text-right'}}">
          {{getLang == 'en' ? "Home is your safe place." :"منزلك هو مكانك الآمن"}}
        </p>
        <p class="text-Mid-Gray-1 lg:max-w-[456px] md:max-w-[500px] lg:text-lg md:text-base pt-4">
          {{getLang == 'en' ?
        "The great prices, fast claims, and quick renewals are just the beginning. Get the full benefits by signing up with Solidarity." :"الأسعار التنافسية، تقديم المطالبات وتجديد الوثائق في وقت قياسي ليست سوى البداية. احصل على المزايا الكاملة من خلال التسجيل في تطبيق الهاتف الذكي"}}
        </p>
        <a href="https://online.solidarity.bh">
          <button
            class="button h-10 w-60 mt-10 mr-3 bg-primary-500 text-Light-Gray-5 py-2 px-4 rounded-md items-center text-sm justify-around">
            <div class="flex justify-center">
              <p class="pr-3">
                {{getLang == 'en' ? "Get a Quote" : "تسعيرة التأمين"}} 
              </p>
              <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                [ngClass]="getLang == 'ar' ? 'scale-x-[-1]' : ''">
                <path d="M20.5 12H4.5H20.5Z" fill="white" />
                <path d="M16.5 8L20.5 12M20.5 12L16.5 16M20.5 12H4.5" stroke="white" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </div>
          </button>
        </a>
      </div>
      <div class="flex justify-between pt-8 lg:block md:block sm:hidden">
        <div></div>
        <img class="lg:w-[700px] lg:h-[480px] md:w-[550px] sm:w-[288px] lg:pb-8 md:pr-10" src="assets/imgs/lowerbanner.jpg" alt="">
      </div>
    </div>
  </div>
</div>