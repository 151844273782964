<app-header bg="bg-primary-50"></app-header>
<div class="p-2 w-full bg-primary-50 items-center lg:min-h-[610px] md:min-h-[596px] sm:min-h-[348px]  relative">
  <div class="mx-auto w-full text-center lg:mt-2 md:mt-16 lg:max-w-fit md:max-w-fit sm:container sm:max-w-md md:container">
    <div class="lg:text-7xl font-medium text-Dark-Gray-2 sm:text-4xl md:text-6xl">{{getLang == 'en' ? "We are Solidarity" : "نحن سوليدرتي"}}</div>
    <p class="lg:text-xl lg:w-3/5 md:text-base sm:text-sm md:w-4/5 text-center mx-auto text-Mid-Gray-1 pt-6">
      {{getLang == 'en' ? "We care about our customers. We’re over 40 years strong and we’re looking towards the future of insurance." : "نحن نهتم بعملائنا منذ أكثر من ٤٠ عاماً، ونتطلع قدماً لجعل مستقبل التأمين  أكثر ملائمة لاحتياجاتك"}}
    </p>
  </div>

  <div
    class="mx-auto  absolute lg:-bottom-1/2 md:-bottom-[10%] sm:-bottom-[20%] inset-x-0 container sm:max-w-md lg:max-w-6xl md:max-w-2xl md:mt-14 lg:mt-0 sm:mt-5 z-10">
    <!-- <img class=" mx-auto  w-full h-full" src="assets/imgs/aboutus.jpg" id="box" /> -->

    <img class=" mx-auto  w-full h-full" [src]="strapiApi.getImage(companyBio.hero.data.attributes.url)" id="box" />
  
  </div>


</div>
<div class="p-2 w-full bg-white items-center" dir="auto">
  <div class="mx-auto w-full text-start lg:mt-80  mb-40 md:container sm:container  sm:mt-14 md:mt-20">
    <div>
      <div class="lg:text-6xl sm:text-3xl font-medium text-Dark-Gray-2">{{getLang == 'en' ? "Our bio" : "سيرتنا الذاتية"}}</div>
      <p class="sm:text-sm md:text-base lg:text-lg lg:w-3/5 sm:w-5/5 text-Mid-Gray-1 pt-5">
        {{getLang == 'en' ? companyBio.companyBio : companyBio.companyBio_ar}}
      </p>
    </div>
    <div class="mt-16" dir="auto">
      <div class="lg:text-6xl sm:text-4xl font-medium text-Dark-Gray-2">
        {{getLang == 'en' ? "Board of Directors" : "مجلس الإدارة "}}
      </div>
      <div class="mt-5 grid  lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-6  ">
        <div class="" *ngFor=" let member of board">
          <img class="drop-shadow-lg" [src]="strapiApi.getImage(member.image.data.attributes.url)" />
          <p class="font-medium lg:text-2xl text-Dark-Gray-3 mt-6 sm:text-sm">
            {{getLang == 'en' ? member.title_en : member.title_ar }}
          </p>
          <p
            class="lg:text-lg  lg:text-Mid-Gray-1 mt-1 md:text-sm md:text-Mid-Gray-1 mt-1 sm:text-xs sm:text-Mid-Gray-1 mt-1">
            {{getLang == 'en' ? member.description_en : member.description_ar}}
          </p>
        </div>

      </div>
    </div>
    <div class="mt-24" dir="auto">
      <div class="lg:text-6xl sm:text-3xl font-medium text-Dark-Gray-2">
        {{getLang == 'en' ? "Sharia Supervisory Board" : "هيئة الرقابة الشرعية"}}
      </div>
      <div class="mt-5 grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-6   ">
        <div *ngFor=" let member of sharia" class="">
          <img class="drop-shadow-lg" [src]="strapiApi.getImage(member.image.data.attributes.url)" />
          <p class="font-medium lg:text-2xl text-Dark-Gray-3 mt-6 sm:text-sm">
            {{getLang == 'en' ? member.title_en : member.title_ar }}
          </p>
          <p
            class="lg:text-lg  lg:text-Mid-Gray-1 mt-1 md:text-sm md:text-Mid-Gray-1 mt-1 sm:text-xs sm:text-Mid-Gray-1 mt-1">
            {{getLang == 'en' ? member.description_en : member.description_ar}}
          </p>
        </div>



      </div>
    </div>
    <div class="mt-24" dir="auto">
      <div class="lg:text-6xl sm:text-3xl font-medium text-Dark-Gray-2">
        {{getLang == 'en' ? "Management" : "الإدارة"}}
      </div>
      <div class="mt-5 grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-6  ">
        <div *ngFor=" let member of managment.slice(0,3) " class="">
          <img class="drop-shadow-lg" [src]="strapiApi.getImage(member.image.data.attributes.url)" />
          <p class="font-medium lg:text-2xl text-Dark-Gray-3 mt-6 sm:text-sm">
            {{getLang == 'en' ? member.title_en : member.title_ar }}
          </p>
          <p
            class="lg:text-lg  lg:text-Mid-Gray-1 mt-1 md:text-sm md:text-Mid-Gray-1 mt-1 sm:text-xs sm:text-Mid-Gray-1 mt-1">
            {{getLang == 'en' ? member.description_en : member.description_ar}}
          </p>
        </div>
      </div>
      <div class="mt-5 grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-6  ">
        <div *ngFor=" let member of managment.slice(3) " class="">
          <img class="drop-shadow-lg" [src]="strapiApi.getImage(member.image.data.attributes.url)" />
          <p class="font-medium lg:text-2xl text-Dark-Gray-3 mt-6 sm:text-sm">
            {{getLang == 'en' ? member.title_en : member.title_ar }}
          </p>
          <p
            class="lg:text-lg  lg:text-Mid-Gray-1 mt-1 md:text-sm md:text-Mid-Gray-1 mt-1 sm:text-xs sm:text-Mid-Gray-1 mt-1">
            {{getLang == 'en' ? member.description_en : member.description_ar}}
          </p>
        </div>
      </div>
    </div>
  </div>
  <app-coverd-section> </app-coverd-section>
  <app-footer></app-footer>
</div>