<app-header bg="bg-white"></app-header>
<div class="mx-auto max-w-7xl px-4 sm:px-6 container" dir="auto">
  <p class="lg:text-7xl md:text-6xl sm:text-[40px] pt-[100px]">
    {{getLang == 'en' ? "Investor Relations" : "علاقات المستثمرين"}}</p>
  <p class="pt-5 lg:text-[18px] sm:text-sm md:text-base text-Mid-Gray-1 lg:w-2/3">
    {{getLang == 'en' ?
    "We strive to create the best outcomes for our clients. We offer financial solutions"
    +" that are simple, creative and responsible and bundled with world-class customer"
    +" service. We always aim to generate superior returns to all our shareholders."
    :
    "نحن نسعى جاهدين لخلق أفضل النتائج لعملائنا. نحن نقدم حلولًا "+
    "مالية بسيطة ومبتكرة ومسؤولة ومدمجة مع خدمة عملاء عالمية المستوى. نهدف دائمًا إلى تحقيق عوائد عالية لجميع مساهمينا."
    }}</p>
  <div class="pt-12">
    <div class="lg:hidden md:hidden">
      <label for="tabs" class="sr-only">Select a tab</label>
      <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
      <select id="tabs" #teams name="tabs"
        class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
        (change)="onSelected(teams.value)">
        <option value="news" selected *ngIf="showNews">
          {{getLang == 'en' ? "Shareholder News" : "أخبار المساهمين"}}
        </option>
        <option value="news" *ngIf="!showNews">
          {{getLang == 'en' ? "Shareholder News" : "أخبار المساهمين"}}</option>
        <option value="query" selected *ngIf="showQuery">
          {{getLang == 'en' ? "Shareholder Query" : "استعلامات المساهمين"}}</option>
        <option value="query" *ngIf="!showQuery">
          {{getLang == 'en' ? "Shareholder Query" : "استعلامات المساهمين"}}
        </option>
        <option value="reports" selected *ngIf="showReports">
          {{getLang == 'en' ? "Financial Reports" : "التقارير المالية"}}</option>
        <option value="reports" *ngIf="!showReports">{{getLang == 'en' ? "Financial Reports" : "التقارير المالية"}}
        <option value="reports" selected *ngIf="showReports">
          {{getLang == 'en' ? "Credit Report" : "تقرير الائتمان"}}</option>
        <option value="credit" *ngIf="!showCredit">{{getLang == 'en' ? "Credit Report" : "تقرير الائتمان"}}
        </option>
      </select>
    </div>
    <div class="sm:hidden lg:block md:block">
      <div class="border-b border-Light-Gray-3" dir="auto">
        <nav class="-mb-px flex space-x-4" aria-label="Tabs">
          <!-- Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" -->
          <button class="button"
            [ngClass]="showNews ? 'border-indigo-500 text-primary-500  hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pt-4 px-2 border-b-2 font-medium lg:text-[26px] md:text-lg' : 'border-transparent text-Mid-Gray-3 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pt-4 px-1 border-b-2 font-medium lg:text-[26px] md:text-lg'"
            (click)="ShowNews()">{{getLang == 'en' ? "Shareholder News" : "أخبار المساهمين"}}</button>

          <button class="button"
            [ngClass]="showQuery ? 'border-indigo-500 text-primary-500  hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pt-4 px-5 border-b-2 font-medium lg:text-[26px] md:text-lg' : 'border-transparent text-Mid-Gray-3 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pt-4 px-3 border-b-2 font-medium lg:text-[26px] md:text-lg'"
            (click)="ShowQuery()">{{getLang == 'en' ? "Shareholder Query" : "استعلامات المساهمين"}}</button>

          <button class="button"
            [ngClass]="showReports ? 'border-indigo-500 text-primary-500  hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pt-4 px-2 border-b-2 font-medium lg:text-[26px] md:text-lg' : 'border-transparent text-Mid-Gray-3 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pt-4 px-1 border-b-2 font-medium lg:text-[26px] md:text-lg'"
            (click)="ShowReports()">{{getLang == 'en' ? "Financial Reports" : "التقارير المالية"}}</button>

          <button class="button"
            [ngClass]="showCredit ? 'border-indigo-500 text-primary-500  hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pt-4 px-2 border-b-2 font-medium lg:text-[26px] md:text-lg' : 'border-transparent text-Mid-Gray-3 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pt-4 px-1 border-b-2 font-medium lg:text-[26px] md:text-lg'"
            (click)="ShowCredits()">{{getLang == 'en' ? "Credit Report" : "تقرير الائتمان"}}</button>
        </nav>
      </div>
    </div>
    <div [ngClass]="showNews ? 'block' : 'hidden'">
      <app-shareholdernews></app-shareholdernews>
    </div>
    <!--Shareholder Query-->
    <div [ngClass]="showQuery? 'block' : 'hidden'">
      <p class="text-Mid-Gray-1 lg:text-lg md:text-base sm:text-sm pt-8 pb-4 lg:w-[648px] md:w-[648px]">
        {{getLang == 'en' ? "We value your requests and queries. Kindly fill the form"
        +" below and our investor relations team will revert back to you soon"
        : "آراؤكم واستفساراتكم تهمنا، الرجاء ملىء الاستمارة أدناه "
        +"وسيقوم فريق علاقات المستثمرين بالتواصل معكم في أقرب وقت ممكن"}}
      </p>
      <form #myform="ngForm" [formGroup]="ngForm" (ngSubmit)="SendInvestorRelation(ngForm)">
        <div class="lg:max-w-3xl md:max-w-none sm:max-w-none  ">
          <div class=" h-full items-center   ">
            <div class="lg:flex md:flex">
              <div class="flex-1 mt-5 " [ngClass]="getLang == 'ar' ? 'pl-4' : 'pr-4' ">
                <label for="email" class="block text-sm font-normal text-Dark-Gray-2 mb-2 dark:text-white">
                  {{getLang == 'en' ? "First Name" : "الاسم الأول"}}</label>
                <div class="relative">
                  <input type="email" id="fname" name="fnamme" placeholder="e.g. John"
                    class="h-10 py-2 px-4 block w-full border border-Light-Gray-2 rounded-md text-sm " required
                    aria-describedby="email-error" formControlName="fname" />
                </div>
              </div>
              <div class="flex-1 mt-5">
                <label for="email" class="block text-sm font-normal text-Dark-Gray-2 mb-2 dark:text-white">
                  {{getLang == 'en' ? "Last Name" : "الاسم الأخير"}}</label>
                <div class="relative">
                  <input type="lname" id="lname" name="lname" placeholder="e.g. Doe"
                    class="h-10 py-2 px-4 block w-full border border-Light-Gray-2 rounded-md text-sm " required
                    aria-describedby="email-error" formControlName="lname" />
                </div>
              </div>
            </div>
            <div class="lg:flex md:flex mt-5">
              <div class="flex-1 mt-5" [ngClass]="getLang == 'ar' ? 'pl-4' : 'pr-4' ">
                <label for="phone" class="block text-sm font-normal text-Dark-Gray-2 mb-2 dark:text-white">
                  {{getLang == 'en' ? "Mobile Number" : "رقم الهاتف"}}</label>
                <div class="relative flex">
                  <select name="" id=""
                    class="h-10 py-2  pl-4  block border w-24 border-Light-Gray-2 rounded-md text-sm">
                    <option value="">+973</option>
                    <option value="">+873</option>
                    <option value="">+673</option>
                  </select>
                  <input type="phone" id="phone" name="phone" placeholder=""
                    class="h-10 py-2 px-4 block w-full border border-Light-Gray-2 rounded-md text-sm " required
                    aria-describedby="email-error" [ngClass]="getLang == 'ar' ? 'mr-2' : 'ml-2' "
                    formControlName="phone" />
                </div>
              </div>
              <div class="flex-1 mt-5">
                <label for="email" class="block text-sm font-normal text-Dark-Gray-2 mb-2 dark:text-white">
                  {{getLang == 'en' ? "Email address" : "البريد الإلكتروني"}}</label>
                <div class="relative">
                  <input type="email" id="email" name="email" placeholder="e.g name@example.com"
                    class="h-10 py-2 px-4 block w-full border border-Light-Gray-2 rounded-md text-sm " required
                    aria-describedby="email-error" formControlName="email" />
                </div>
              </div>
            </div>

            <div class="flex mt-5">
              <div class="w-full lg:mt-5 md:mt-5">
                <label for="subject" class="block text-sm font-normal text-Dark-Gray-2 mb-2 dark:text-white">
                  {{getLang == 'en' ? "Subject" : "الموضوع"}}</label>
                <div class="relative">
                  <input type="text" id="subject" name="subject"
                    placeholder="{{getLang == 'en' ? 'Title of what your message is about' : 'موضوع الرسالة'}}"
                    class="h-10 py-2 px-4 block w-full border border-Light-Gray-2 rounded-md text-sm " required
                    aria-describedby="subject-error" formControlName="subject" />
                </div>
              </div>
            </div>
            <div class="flex mt-5">
              <div class="w-full lg:mt-5 md:mt-5">
                <label for="message" class="block text-sm font-normal text-Dark-Gray-2 mb-2 dark:text-white">
                  {{getLang == 'en' ? "Query" : "الاستفسار"}}</label>
                <div class="relative">
                  <textarea type="text" id="message" name="message"
                    placeholder="{{getLang == 'en' ? 'Type your message here' : 'كتب رسالتك هنا'}}"
                    class="py-2 px-4 block w-full h-20 border border-Light-Gray-2 rounded-md text-sm " required
                    aria-describedby="message-error" formControlName="query"></textarea>
                  <!-- <input
                type="text"
                id="message"
                name="message"
                class="py-2 px-4 block w-full border border-Light-Gray-2 rounded-md text-sm "
                required
                aria-describedby="message-error"
              /> -->
                </div>
              </div>
            </div>
            <br>
            <div class="flex mt-2 px-2 items-center justify-center">
              <re-captcha (resolved)="resolved($event)"></re-captcha>
            </div>
            <div class="flex mt-12">
              <div class="flex-1">
                <div class="w-full  mb-5">
                  <button [disabled]="!captcharesolved"
                    class="button block lg:w-full md:w-[216px] sm:w-full bg-primary-500 text-Light-Gray-5 rounded-md px-3 py-3 font-medium text-lg">
                    {{getLang == 'en' ? "Submit" : "تقديم"}}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

    </div>

    <!--Finincial Reports-->
    <div [ngClass]="showReports ? 'block' : 'hidden'">
      <app-financialreports></app-financialreports>
    </div>

    <!--Credit Report-->
    <div [ngClass]="showCredit ? 'block' : 'hidden'">
      <app-credit-report></app-credit-report>
    </div>

  </div>
</div>

<app-footer></app-footer>